/* eslint-disable import/no-unresolved */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable object-curly-newline */
import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';

import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { addOrder as addOrderAction } from '../actions';
import { CREATENEWORDER } from '../mutations';
import CheckoutForm from './CheckoutForm';

const CheckoutContainer = styled.section`
  width: ${({ mobile }) => (mobile ? '80%' : '50%')};
  margin-left: auto;
  overflow: text-wrap;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: calc(100vh - 250px);
  overflow: scroll;
  overflow-x: hidden;
`;

const PaymentTitle = styled.h2`
  place-self: center;
  color: ${(props) => props.theme.colors.blue};
`;

const SubFields = styled.h3`
  place-self: start;

  color: ${(props) => props.theme.colors.blue};
`;

const PaymentApp = styled.section`
  height: calc(100vh - 175px);
  padding-top: 30px;
`;

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPEPK);

function Checkout() {
  const form = useSelector((state) => state.payment.form);
  const [clientSecret, setClientSecret] = useState('');
  const usingMobile = useSelector(({ mobile }) => mobile.mobile);
  const itemSelected = useSelector(({ payment }) => payment.item.name);
  const userId = useSelector(({ payment }) => payment.userid);
  const [total, setTotal] = useState({ total: 0, originalTotal: 0 });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [addOrder, { loading, data: orderData }] = useMutation(CREATENEWORDER);

  useEffect(() => {
    if (orderData?.createOrder?.success && !loading) {
      dispatch(addOrderAction(orderData.createOrder.order));
    }
  }, [orderData]);

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    if (form.email !== '') {
      axios
        .post(`${process.env.REACT_APP_API}/api/create-payment-intent`, {
          item: itemSelected,
          email: form.email,
          code: form.code,
        })
        .then(({ data }) => {
          addOrder({
            variables: {
              input: {
                item: itemSelected,
                userId,
                total: data.total,
              },
            },
          });

          setTotal({ total: data.total, originalTotal: data.originalTotal });
          setClientSecret(data.clientSecret);
        });
    }
  }, [form.email, itemSelected.item, userId]);

  if (form.email === '') {
    navigate('/home');
  }

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  if (!clientSecret || loading || !orderData) {
    return <CircularProgress style={{ placeSelf: 'center' }} />;
  }

  const isDiscounted = total.total !== total.originalTotal;

  return (
    <PaymentApp>
      <CheckoutContainer mobile={usingMobile}>
        <PaymentTitle>Coxhoe Trail Run</PaymentTitle>
        <SubFields>Email: {form.email}</SubFields>
        <SubFields>OrderId: {orderData.createOrder.order._id}</SubFields>

        <SubFields>Item: {orderData.createOrder.order.item}</SubFields>
        {isDiscounted ? (
          <>
            <SubFields style={{ fontSize: '0.75rem', color: 'darkgreen' }}>
              *Discount applied with code {form.code}*
            </SubFields>
            <SubFields>
              Total:{' '}
              <span
                style={{ textDecoration: 'line-through', color: 'darkred' }}
              >
                £{total.originalTotal}
              </span>{' '}
              <span style={{ color: 'darkgreen' }}>£{total.total}</span>
            </SubFields>
          </>
        ) : (
          <SubFields>Total: £{total.total}</SubFields>
        )}

        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      </CheckoutContainer>
    </PaymentApp>
  );
}
export default Checkout;
