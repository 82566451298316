import { gql } from '@apollo/client';
import { CORE_ORDER_FIELDS } from './fragments';

export const CREATENEWORDER = gql`
  ${CORE_ORDER_FIELDS}
  mutation CreateOrder($input: CreateOrderInput!) {
    createOrder(input: $input) {
      success
      order {
        ...CoreOrderFields
      }
    }
  }
`;

export const UPDATEORDERSTATUS = gql`
  mutation UpdateOrderStatus($input: UpdateOrderStatusInput) {
    updateOrderStatus(input: $input) {
      success
    }
  }
`;

export const CREATE_NEW_USER = gql`
  mutation CreateUser($input: UserCreation!) {
    createUser(input: $input) {
      success
      userId
    }
  }
`;
