/* eslint-disable import/no-cycle */
/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { rem } from 'polished';
import { useState } from 'react';
import { FaHome } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import styled from 'styled-components';
import { Hamburger } from './Hamburger';

import Logo from '../images/logo_activelife.png';
import { Button } from './Button';
import { Anchor } from './Text';

const HeaderContainer = styled.header`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(144px, 1fr));
  place-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: ${(props) => props.theme.colors.white};

  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  img {
    max-width: ${rem('144px')};
    max-height: ${rem('70px')};
  }

  li {
    display: inline-block;
    margin-left: ${rem('20px')};
  }

  ul {
    list-style-type: none;
    padding: 0;
  }

  .icon {
    font-size: ${rem('35px')};
  }

  .fillBlue {
    fill: ${(props) => props.theme.colors.blue};
    :hover {
      fill: ${(props) => props.theme.colors.yellow};
    }
  }

  .outlineBlue {
    color: ${(props) => props.theme.colors.blue};
    :hover {
      color: ${(props) => props.theme.colors.yellow};
    }
  }
`;

const HeaderButton = styled(Button)`
  border: none;
  background: none;
  cursor: pointer;

  :hover {
    border: none;
    background: none;
  }
`;

const MobileNavContainer = styled.nav`
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 98;
  background-color: #031a6b91;
  visibility: visible;
`;

const MobileNav = styled.ul`
  height: 80vh;
  position: absolute;
  top: 20vh;
  right: 8%;
  display: flex;
  flex-direction: column;
  list-style: none;
`;

const MobileNavItems = styled.li`
list-item
  flex: 1 0 20%;
  color: white;
  place-self: center;
  display: grid;
  height: 100%;
  place-items: center;
  font-size: 1.4rem;

`;

const NavButtons = styled.a`
  color: ${(props) => (props.mobile ? 'white' : props.theme.colors.blue)};
  text-decoration: none;
  :hover {
    color: ${(props) => props.theme.colors.yellow};
  }
`;

const locationParser = (location) => {
  switch (location) {
    case '/home':
      return 'Home';
    case '/paymentconfirmation':
      return 'Payment Confirmation';
    case '/checkout':
      return 'Checkout';
    case '/privacy':
      return 'Privacy';
    case '/refunds':
      return 'Transfers / Refunds';
    case '/register':
      return 'Registration';
    default:
      return 'Payment Confirmation';
  }
};
// { setModal, modalOn, userLoggedIn }

const HeaderComponentChildren = ({
  usingMobile,
  location,
  setToggleMobileNav,
  mobileNavToggle,
  urlTrackingEndpoints,
  navigate,
}) => {
  const homeUrls = [...urlTrackingEndpoints, '/home', '/'];
  if (usingMobile) {
    if (homeUrls.some((url) => location.pathname === url)) {
      return (
        <Anchor
          aria-hidden="true"
          onClick={() => setToggleMobileNav(!mobileNavToggle)}
          style={{
            alignSelf: 'center',
            justifySelf: 'end',
            marginRight: '30px',
            zIndex: '99',
          }}
        >
          <Hamburger isActive={mobileNavToggle} />
        </Anchor>
      );
    } else {
      return (
        <>
          <ul>
            <li>
              <HeaderButton
                onClick={() => {
                  navigate('/home');
                }}
                type="button"
              >
                <FaHome className="icon fillBlue" />
              </HeaderButton>
            </li>
            <li>
              <Anchor href="mailto:info@activelifecoxhoe.co.uk">
                <HiOutlineMail className="icon outlineBlue" />
              </Anchor>
            </li>
          </ul>
        </>
      );
    }
  } else {
    if (homeUrls.some((url) => location.pathname === url)) {
      return (
        <>
          <nav>
            <ul>
              <li>
                <NavButtons href="#aboutUs">About Us</NavButtons>
              </li>
              <li>
                <NavButtons href="#sponsors">Sponsors</NavButtons>
              </li>
              <li>
                <NavButtons href="#registration">Registration</NavButtons>
              </li>
            </ul>
          </nav>
          <ul>
            <li>
              <HeaderButton
                onClick={() => {
                  navigate('/home');
                }}
                type="button"
              >
                <FaHome className="icon fillBlue" />
              </HeaderButton>
            </li>
            <li>
              <Anchor href="mailto:info@activelifecoxhoe.co.uk">
                <HiOutlineMail className="icon outlineBlue" />
              </Anchor>
            </li>
          </ul>
        </>
      );
    } else {
      return (
        <>
          <h3>{locationParser(location.pathname)}</h3>{' '}
          <ul>
            <li>
              <HeaderButton
                onClick={() => {
                  navigate('/home');
                }}
                type="button"
              >
                <FaHome className="icon fillBlue" />
              </HeaderButton>
            </li>
            <li>
              <Anchor href="mailto:bal@activelifecoxhoe.co.uk">
                <HiOutlineMail className="icon outlineBlue" />
              </Anchor>
            </li>
          </ul>
        </>
      );
    }
  }
};

const Header = ({ urlTrackingEndpoints }) => {
  const location = useLocation();
  const [mobileNavToggle, setToggleMobileNav] = useState(false);
  const usingMobile = useSelector(({ mobile }) => mobile.mobile);

  const navigate = useNavigate();

  return (
    <HeaderContainer>
      <img src={Logo} alt="logo" />
      {HeaderComponentChildren({
        location,
        mobileNavToggle,
        usingMobile,
        navigate,
        urlTrackingEndpoints,
        setToggleMobileNav,
      })}
      <>
        {/* <Button
            onClick={() => {
              setModal(!modalOn);
            }}
          >
            {userLoggedIn ? 'Sign Out ' : 'Sign In'}
          </Button> */}
      </>

      {mobileNavToggle && (
        <MobileNavContainer>
          <MobileNav>
            <MobileNavItems>
              <NavButtons
                href="#aboutUs"
                mobile
                onClick={() => setToggleMobileNav(false)}
              >
                About Us
              </NavButtons>
            </MobileNavItems>
            <MobileNavItems>
              <NavButtons
                href="#sponsors"
                mobile
                onClick={() => setToggleMobileNav(false)}
              >
                Sponsors
              </NavButtons>
            </MobileNavItems>
            <MobileNavItems>
              <NavButtons
                href="#location"
                mobile
                onClick={() => setToggleMobileNav(false)}
              >
                Location
              </NavButtons>
            </MobileNavItems>
            <MobileNavItems>
              <NavButtons
                href="#registration"
                mobile
                onClick={() => setToggleMobileNav(false)}
              >
                Registration
              </NavButtons>
            </MobileNavItems>
          </MobileNav>
        </MobileNavContainer>
      )}
    </HeaderContainer>
  );
};
export default Header;
