import styled from 'styled-components';

import { useQuery } from '@apollo/client';
import { rem } from 'polished';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { GetAllSponsors } from '../queries';
import { Heading3 } from './Text';

SwiperCore.use([Navigation, Pagination, Autoplay]);

const SliderContainer = styled.section`
  display: grid;
  background-color: ${(props) => props.theme.colors.blue};
  height: 100%;

  --swiper-theme-color: white !important;

  @media screen and (max-width: 700px) {
    .swiper-button-next {
      right: 0px !important;
    }

    .swiper-button-prev {
      left: 0px !important;
    }

    h3 {
      font-size: 1.2rem !important;
    }

    p {
      width: 90% !important;
      font-size: 0.8rem !important;
    }
  }

  .slider {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding: ${rem('20px')};
  }

  .slide {
    display: grid;
    place-items: center;
    grid-template-rows: ${rem('50px')} 1fr;

    h3 {
      font-size: 2rem;
      color: ${(props) => props.theme.colors.white};
    }

    p {
      color: white;
      text-align: center;
      width: 100ch;
      font-size: 1.1rem;
    }
  }

  .slide-small {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr) !important;
  }

  .singleText {
    grid-template-rows: 1fr;
  }
`;

const SponsorH3 = styled(Heading3)`
  text-align: center;
`;

const SponsorsComponent = () => {
  const { data, loading } = useQuery(GetAllSponsors);

  if (loading) return <p>Loading...</p>;
  return (
    <SliderContainer id="sponsors">
      <Swiper
        className="slider"
        pagination={{ dynamicBullets: true }}
        navigation
        slidesPerView={1}
        spaceBetween={30}
        centeredSlides
        autoplay={{ delay: 8000, disableOnInteraction: false }}
      >
        {data.sponsors.map(({ name }) => (
          <SwiperSlide className="slide singleText" key={name}>
            <SponsorH3>{name}</SponsorH3>
          </SwiperSlide>
        ))}
      </Swiper>
    </SliderContainer>
  );
};

export default SponsorsComponent;
