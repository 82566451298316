import { rem } from 'polished';
import { useEffect, useRef, useState } from 'react';
import { FaAward, FaTshirt } from 'react-icons/fa';
import styled, { keyframes } from 'styled-components';

const fill = keyframes`
  from{
    fill: white;
  }
  to{
    fill: #ffc100;
  }
`;

const iconWiggle = keyframes`
  20%{
    transform: rotate(60deg);
  }

  40%, 80%{
    transform: rotate(0deg);
  }

  60%{
    transform: rotate(-60deg);
  }

`;

const OfferSection = styled.section`
  background-color: ${(props) => props.theme.colors.blue};

  .offerSection {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(${rem('200px')}, 1fr));
    place-items: center;
    height: 100%;

    @media screen and (max-width: ${rem('700px')}) {
      grid-template-rows: auto;
      grid-gap: ${rem('20px')};
    }
  }

  .icon-animation {
    animation: ${fill} 1s ease;
    animation-fill-mode: both;
  }

  .icon {
    font-size: ${rem('75px')};
    fill: ${(props) => props.theme.colors.white};
    transition: transform 0.3s, fill 0.3s;

    :hover {
      animation: ${iconWiggle} 3s ease;
      fill: ${(props) => props.theme.colors.yellow};
    }
  }

  .col {
    display: grid;
    place-items: center;
    grid-gap: ${rem('10px')};
  }

  h4 {
    font-size: 1.5rem;
    color: ${(props) => props.theme.colors.white};
  }
`;

const OfferComponent = () => {
  const containerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const observor = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        });
      },
      { root: null, rootMargin: '0px', threshold: 1 },
    );

    if (containerRef.current) observor.observe(containerRef.current);

    return () => {
      observor.disconnect();
    };
  }, [containerRef]);

  return (
    <OfferSection>
      <div
        className={`container80 offerSection ${isVisible && 'slideIn'}`}
        ref={containerRef}
      >
        <div className="col">
          <FaAward className={`icon ${isVisible && 'icon-animation'}`} />
          <h4>11 Successful Years</h4>
        </div>
        <div className="col">
          <FaTshirt className="icon" />
          <h4>Trail Run T-shirt</h4>
        </div>
        <div className="col">
          <FaTshirt className="icon" />
          <h4>Fully Marshalled</h4>
        </div>
      </div>
    </OfferSection>
  );
};

export default OfferComponent;
