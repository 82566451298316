import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useQuery } from '@apollo/client';
import { rem } from 'polished';
import { useNavigate } from 'react-router';
import { setPaymentItemSelected } from '../actions';
import { GetProducts } from '../queries';
import { Button } from './Button';

const RegistrationSection = styled.section`
  display: grid;
  grid-template-rows: ${rem('100px')} ${rem('300px')};
  place-items: center;
  grid-gap: ${rem('20px')};

  h2 {
    font-size: 2.5rem;
    color: ${(props) => props.theme.colors.blue};
  }

  .cardsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(${rem('300px')}, 1fr));
    grid-gap: ${rem('20px')};
    width: 100%;
    height: 100%;
  }

  .card {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: ${rem('300px')};
    height: ${rem('300px')};
    place-self: center;
    border-radius: ${rem('10px')};
    padding: ${rem('20px')};
    display: grid;
    place-items: center;

    ul {
      list-style-type: none;
    }
    li {
      text-align: center;
      margin-bottom: ${rem('5px')};
    }

    p {
      display: grid;
      place-items: center;
      background-color: ${(props) => props.theme.colors.yellow};
      color: ${(props) => props.theme.colors.blue};
      padding: ${rem('25px')};
      border-radius: 50%;
      height: ${rem('90px')};
      width: ${rem('90px')};

      font-size: 1.5rem;
    }

    .none {
      display: none;
    }
  }
`;

const RegistrationPriceComponent = ({ name, price, items }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const priceSelect = () => {
    dispatch(setPaymentItemSelected({ name }));
    navigate('/register');
  };
  return (
    <div className="card">
      <h3>{name}</h3>
      <p>£{price}</p>
      <ul>{items && items.map((item) => <li key={item}>{item}</li>)}</ul>
      {name !== 'On The Day' && <Button onClick={priceSelect}>Register</Button>}
    </div>
  );
};

const RegistrationComponent = () => {
  const { loading, data } = useQuery(GetProducts);

  if (loading) return <p>Loading...</p>;

  return (
    <RegistrationSection className="container80" id="registration">
      <h2>Registration</h2>
      <div className="cardsContainer">
        {data?.products?.map((product) => (
          <RegistrationPriceComponent key={product.name} {...product} />
        ))}
      </div>
    </RegistrationSection>
  );
};
export default RegistrationComponent;
