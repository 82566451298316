import { gql } from '@apollo/client';

export const CORE_ORDER_FIELDS = gql`
  fragment CoreOrderFields on Order {
    _id
    item
    status
    total
  }
`;
