import { gql } from '@apollo/client';

export const GetProducts = gql`
  query Products {
    products {
      name
      price
      items
    }
  }
`;

export const GetAvailableTShirtSizes = gql`
  query AvailableTShirts {
    availableTShirts {
      size
    }
  }
`;

export const GetAllSponsors = gql`
  query Sponsors {
    sponsors {
      name
      description
      level
    }
  }
`;
